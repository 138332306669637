<i18n src="@/locales/reject.json" />

<template>
  <div class="reject">
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title')"
      />
    </div>
    <ImanH2>{{ $t('h2_title_1') }}</ImanH2>
    <section class="section">
      <div class="columns is-centered">
        <div class="column is-half">
          <ImanVideo :video="video" />
        </div>
      </div>
    </section>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[0]">
        <p>
          <span v-html="$t('article_p1')" />
        </p>
        <ImanH3>{{ $t('h3_title_1') }}</ImanH3>
        <p>
          {{ $t('article_p2_1') }}
        </p>
        <p>
          {{ $t('article_p2_2') }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_1')">
          {{ $t('more_info_1') }}
        </ImanMoreInfo>
      </ImanHomeSection>
    </section>
    <ImanH2>{{ $t('h2_title_2') }}</ImanH2>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[1]">
        <ImanH3>
          {{ $t('h3_title_2') }}
        </ImanH3>
        <p>
          {{ $t('article_p3') }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_2')">
          {{ $t('more_info_2') }}
        </ImanMoreInfo>
      </ImanHomeSection>
    </section>
  </div>
</template>

<script>
  import ImanMoreInfo from "@/components/ImanMoreInfo";
  import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
  import {metaMixin} from "@/mixins/meta-mixin";

  export default {
    name: 'Reject',
    components: {
      ImanMoreInfo
    },
    mixins: [
      articlesCardContentFilterMixin,
      metaMixin
    ],
    data() {
      return {
        mainBackgroundClass: 'none-background',
        img: [
          {
            path: 'dental-implant/basal-implant/comparative/reject/causes-rejet-implant-dentaire.jpg',
            position: 'right',
            alt: 'Causes du rejet infection d\'un implant dentaire'
          },
          {
            path: 'dental-implant/basal-implant/comparative/reject/eviter-le-rejet-implant-dentaire-basal.jpg',
            position: 'right',
            alt: 'Comment l\'implant dentaire basal permet d\'éviter le rejet'
          }
        ],
        video: {
          src: 'https://www.youtube.com/embed/Oah1QTFIcQ4'
        }
      }
    },
    computed: {
      openGraphImgPath () {
        return 'https://anveli.dental' + require('../../../../assets/img/thumbnail/MINIATURE_2.1.2.png')
      }
    },
    created() {
      window.addEventListener('scroll', this.whenScroll)
    },
    destroyed() {
      window.removeEventListener('scroll', this.whenScroll)
    },
    methods: {
      whenScroll() {
        if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
          this.mainBackgroundClass = 'none-background'
        }
        if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
          this.mainBackgroundClass = 'fixed-background'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header-background {
    @media only screen and (min-width: 768px) {
      /* tablet et ordinateurs */
      background-image: url("../../../../assets/img/dental-implant/basal-implant/comparative/reject/H1-implant-dentaire-basal-sans-rejet-d.jpg");
    }
    @media only screen and (max-width: 767px) {
      /* smartphone en mode paysage */
      background-image: url("../../../../assets/img/dental-implant/basal-implant/comparative/reject/H1-implant-dentaire-basal-sans-rejet-t.jpg");
    }
    @media only screen and (max-width: 767px) and (orientation: portrait) {
       /* smartphone en mode portait */
      background-image: url("../../../../assets/img/dental-implant/basal-implant/comparative/reject/H1-implant-dentaire-basal-sans-rejet-m.jpg");
    }
  }
</style>
